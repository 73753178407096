<template>
  <v-select
    :items="managedResidences"
    item-text="name"
    item-value="id"
    v-model="selectedResidence"
    return-object
    :loading="loading"
    :label="$t('residence.name')"
  ></v-select>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
      managedResidences: [],
    };
  },
  props: {
    value: Object,
    autoSelectFirst: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    managedResidences: {
      query: gql`
        query ManagedResidences {
          me {
            id
            managedResidences {
              id
              name
              area {
                id
                abbreviation
              }
            }
          }
        }
      `,
      update(data) {
        if (this.autoSelectFirst && data.me?.managedResidences?.length > 0)
          this.selectedResidence = data.me.managedResidences[0];
        return data.me.managedResidences;
      },
    },
  },
  computed: {
    selectedResidence: {
      get() {
        const id = this.$route.query.residence_id;
        return id ? this.managedResidences.find((residence) => residence.id === id) : null;
      },
      set(value) {
        if (value == null && !this.$route.query.residence_id) return;
        if (value && value.id == this.$route.query.residence_id) return;
        if (value == null) {
          // eslint-disable-next-line no-unused-vars
          const { residence_id, ...query } = this.$route.query;
          return this.$router.replace({ query });
        } else this.$router.replace({ query: { ...this.$route.query, residence_id: value.id } });
      },
    },
    loading() {
      return this.$apollo.queries.managedResidences.loading;
    },
  },
  watch: {
    selectedResidence() {
      this.$emit("input", this.selectedResidence);
    },
    value(val) {
      this.selectedResidence = val;
    },
  },
};
</script>

<style></style>
