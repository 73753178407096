<template>
  <base-form
    model="user"
    @save="user_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.user.loading"
    :currentValue="user"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    :graphQLErrors="graphQLErrors"
    @cancel="$emit('cancel')"
    ref="form"
    :title="baseItem.type ? $tc(`options.user.type.${baseItem.type}`) : $tc('models.user')"
  />
</template>

<script>
import { required, email, minLength, sameAs, numeric } from "vuelidate/lib/validators";

import gql from "graphql-tag";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import BaseForm from "./BaseForm.vue";

const USER_FRAGENT = gql`
  fragment userFragment on User {
    id
    username
    name
    surname
    email
    phone
    type
    canSendLoginInfo
    meals_allowed
    student {
      id
    }
    residence {
      id
      name
    }
  }
`;

export default {
  components: { BaseForm },
  name: "UserForm",

  props: {
    user_id: Number,
    baseItem: {
      type: Object,
      default: () => ({}),
    },
    duplicating: Boolean,
  },

  data() {
    return {
      newValue: {},
      user: {},
      saving: false,
      graphQLErrors: [],
    };
  },

  watch: {
    newValue() {
      this.graphQLErrors = [];
    },
  },

  apollo: {
    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            ...userFragment
          }
        }
        ${USER_FRAGENT}
      `,
      variables() {
        return {
          id: this.user_id,
        };
      },
      skip() {
        return !this.user_id;
      },
    },
  },

  computed: {
    type_options() {
      return [
        "guest",
        "student",
        "meals_admin",
        "receptionist",
        "course_admin",
        "teacher",
        "residence_admin",
        "area_admin",
        "client_admin",
        "super_admin",
      ].map((value) => ({
        text: this.$tc(`options.user.type.${value}`),
        value,
      }));
    },

    fields() {
      return [
        {
          name: "username",
          validation: {
            required,
          },
          cols: 12,
          bind: {
            readonly: this.user_id ? true : false,
          },
        },
        {
          name: "generate_password",
          type: "checkbox",
          isVisible: () => !this.user_id,
          cols: 12,
        },
        {
          name: "notify_on_create",
          type: "checkbox",
          isVisible: (model) => !this.user_id && model.generate_password,
          cols: 12,
        },
        {
          name: "password",
          validation: {
            minLength: minLength(8),
            ...(this.user_id ? {} : { required }),
          },
          bind: {
            type: "password",
          },
          isVisible: (currentValue) => !currentValue.generate_password,
        },
        {
          name: "password_confirm",
          validation: {
            sameAsPassword: sameAs("password"),
          },
          bind: {
            type: "password",
          },
          isVisible: (currentValue) => !currentValue.generate_password,
        },
        {
          name: "name",
          validation: {
            required,
          },
        },
        {
          name: "surname",
          validation: {
            required,
          },
        },
        {
          name: "email",
          validation: {
            email,
            required,
          },
        },
        {
          name: "phone",
          type: "phone",
          validation: {
            numeric,
          },
        },
        {
          name: "type",
          type: "autocomplete",
          items: this.type_options,
          validation: {
            required,
          },
          isVisible: () => !this.baseItem.type && !(this.user?.type === "candidate"),
        },
        {
          name: "residence",
          type: "query",
          path: "me.managedResidences",
          query: gql`
            query ManagedResidences {
              me {
                id
                managedResidences {
                  id
                  name
                }
              }
            }
          `,
          validation: { required },
        },
        {
          name: "meals_allowed",
          type: "checkbox",
        },
      ];
    },
  },

  methods: {
    update() {
      this.saving = true;

      let input = Object.assign({}, this.newValue);
      delete input.password_confirm;

      const meals_allowed = input.meals_allowed;
      delete input.meals_allowed;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "User",
            fragment: USER_FRAGENT,
            fragment_name: "userFragment",
          }),
          variables: { id: this.user_id, input },
        })
        .then(async ({ data: { updateUser } }) => {
          if (meals_allowed !== undefined) await this.updateMeals(updateUser.id, meals_allowed);

          this.$refs.form.resetInfo();
          this.$emit("save", updateUser);
          this.saving = false;
        })
        .catch(({ graphQLErrors }) => {
          this.graphQLErrors = graphQLErrors;
          this.saving = false;
        });
    },

    updateMeals(user_id, meals_allowed) {
      return this.$apollo.mutate({
        mutation: gql`
          mutation SetUserMealPermissions($id: ID!, $allowed: Boolean!) {
            setUserMealPermissions(id: $id, allowed: $allowed) {
              id
              meals_allowed
            }
          }
        `,
        variables: {
          id: user_id,
          allowed: meals_allowed ?? false,
        },
      });
    },

    create() {
      this.saving = true;

      let input = Object.assign({}, this.newValue);
      delete input.password_confirm;

      if (input.generate_password) delete input.password;
      delete input.generate_password;

      const meals_allowed = input.meals_allowed;
      delete input.meals_allowed;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "User",
            fragment: USER_FRAGENT,
            fragment_name: "userFragment",
          }),
          variables: { input },
        })
        .then(async ({ data: { createUser } }) => {
          await this.updateMeals(createUser.id, meals_allowed);

          this.$refs.form.resetInfo();
          this.$emit("created", createUser);
          this.saving = false;
        })
        .catch(({ graphQLErrors }) => {
          this.graphQLErrors = graphQLErrors;
          this.saving = false;
        });
    },
  },
};
</script>
