<template>
  <base-form
    model="residence"
    @save="residence_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.residence.loading"
    :currentValue="residence"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>


<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";

const RESIDENCE_FRAGMENT = gql`
  fragment residenceForm on Residence {
    id
    name
    meals_enabled
  }
`;

export default {
  components: { BaseForm },
  name: "ExamForm",
  props: {
    residence_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    residence_id(val) {
      if (val == null) this.residence = {};
    },
  },

  apollo: {
    residence: {
      query: gql`
        query Residence($id: ID!) {
          residence(id: $id) {
            ...residenceForm
          }
        }
        ${RESIDENCE_FRAGMENT}
      `,
      variables() {
        return { id: this.residence_id };
      },
      skip() {
        return this.residence_id == null;
      },
    },
  },

  data() {
    return {
      newValue: {},
      residence: {},
      saving: false,
      fields: [
        {
          name: "name",
          validation: {
            required,
          },
        },
        {
          name: "meals_enabled",
          type: "checkbox",
        },
      ],
    };
  },

  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({ model: "Residence", fragment: RESIDENCE_FRAGMENT, fragment_name: "residenceForm" }),
          variables: {
            id: this.residence_id,
            input: this.newValue,
          },
        })
        .then(({ data: { updateResidence } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("updated", updateResidence);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({ model: "Residence", fragment: RESIDENCE_FRAGMENT, fragment_name: "residenceForm" }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createResidence } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createResidence);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>