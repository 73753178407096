<template>
  <v-container>
    <v-btn @click="$refs.avatarPicker.$refs.input.click()" width="100%" height="100%" text fab>
      <v-avatar class="border" :size="size">
        <v-img v-if="user.avatar" aspect-ratio="1" :src="user.avatar.link" />
        <v-img v-else aspect-ratio="1" src="@/assets/blank-avatar.png" />
      </v-avatar>
    </v-btn>
    <v-file-input accept="image/jpeg,image/png,image/bmp" @change="fileSelected" class="d-none" ref="avatarPicker" v-model="newAvatar" />
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">{{ $t("dialog.update_avatar") }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel">{{ $t("dialog.cancel") }}</v-btn>
          <v-btn color="blue darken-1" text @click="updateAvatar">{{ $t("dialog.ok") }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import { FILE_FRAGMENT } from "../../graphql/fragments";

export const AVATAR_FRAGMENT = gql`
  fragment avatar on User {
    id
    avatar: file(name: "avatar") {
      ...file
    }
  }
  ${FILE_FRAGMENT}
`;

export default {
  name: "AvatarPicker",
  props: {
    user: {
      type: Object,
      default: () => null,
    },
    size: {
      default: "100%",
    },
  },
  data() {
    return {
      newAvatar: null,
      dialog: false,
    };
  },
  methods: {
    updateAvatar() {
      this.dialog = false;
      this.$apollo.mutate({
        mutation: gql`
          mutation UpdateUserAvatar($id: ID!, $files: FileInputHasMany!) {
            updateUserAvatar(id: $id, files: $files) {
              id
              ...avatar
            }
          }
          ${AVATAR_FRAGMENT}
        `,
        variables: {
          id: this.user.id,
          files: {
            create: [
              {
                name: "avatar",
                file: this.newAvatar,
              },
            ],
          },
        },
      });
    },

    fileSelected() {
      if (this.newAvatar != null) this.dialog = true;
    },
    cancel() {
      this.newAvatar = null;
      this.dialog = false;
    },
  },
};
</script>
