<template>
  <v-card>
    <v-card-title class="py-2 primary white--text text-h5 d-flex">
      <span v-if="!$apollo.queries.residenceInfo.loading">
        {{ residenceInfo.name }} ({{ residenceInfo.area.abbreviation }}) - {{ users?.paginatorInfo.total }}
        {{ type ? $tc(`options.user.type.${type}`, users?.paginatorInfo.total ?? 0) : $tc("models.user", 2) }}
      </span>

      <v-spacer />
      <v-tooltip v-if="!type" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="showFilters = !showFilters" color="primary" elevation="0" fab small>
            <v-icon v-if="showFilters">mdi-filter-off</v-icon>
            <v-icon v-else>mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("show_filters") }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!type && residenceInfo?.canUpdate">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="editDialog = true" color="primary" elevation="0" fab small>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("dialog.edit") + " " + $tc("models.residence", 1) }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!type && residenceInfo?.canManageUsers">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="toggleActiveStatus"
            :color="!activeStatus ? 'warning' : 'primary'"
            elevation="0"
            fab
            small
          >
            <v-icon>mdi-archive</v-icon>
          </v-btn>
        </template>
        <span>{{ $tc("users.archived_user", 2) }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="residenceInfo?.canManageUsers">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="createDialog = true" color="primary" elevation="0" fab small>
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $tc("users.create_user", 2) }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-row class="pa-0" no-gutters justify="space-between">
        <v-col :class="[{ 'd-none d-md-block': selected }, 'flex-grow-1 flex-shrink-1']" md="5" cols="12">
          <v-card class="pa-2 px-md-4" flat>
            <v-row v-if="showFilters && !type" no-gutters justify="center">
              <v-col cols="12">
                <v-autocomplete
                  :label="$t('filter_by_type')"
                  class="w-100"
                  v-model="typeFilter"
                  item-value="type"
                  item-text="type"
                  :items="userTypes"
                >
                  <template v-slot:selection="data">
                    {{ $tc(`options.user.type.${data.item.type}`) }}
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-icon>
                        <v-icon color="primary"> {{ data.item.icon }} </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $tc(`options.user.type.${data.item.type}`) }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-text-field append-icon="mdi-account-search" :label="$t('search')" v-model="filter" />

            <v-divider></v-divider>

            <v-subheader class="text-uppercase">{{ $tc("models.user", 2) }}</v-subheader>
            <v-row v-if="loading" no-gutters justify="center">
              <v-progress-circular indeterminate color="primary" />
            </v-row>

            <!-- ---DATA--- -->
            <div v-else-if="users.data.length > 0">
              <v-list dense>
                <v-list-item-group v-model="selected" color="primary">
                  <v-list-item v-for="(user, i) in users.data" :key="i" :value="user">
                    <v-list-item-icon>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ user.surname }} {{ user.name }} ({{ user.username }})</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-divider></v-divider>
              <v-pagination text class="pt-1" v-model="page" :length="users.paginatorInfo.lastPage" />
            </div>

            <!-- ---NO RESULTS--- -->
            <v-row v-else no-gutters justify="center">
              <h4 class="text--primary font-italic">
                {{ $t("no_results") }}
              </h4>
            </v-row>

            <!-- ---ERROR--- -->
            <span class="text--danger" v-if="error">{{ error }}</span>
          </v-card>
        </v-col>

        <v-divider class="px-1 d-none d-md-block" vertical></v-divider>

        <v-col md="6" cols="12" class="d-flex text-center align-center">
          <v-scroll-y-transition mode="out-in">
            <div v-if="!selected" class="w-100 d-none d-md-block">
              <span class="text-h6 grey--text text--lighten-1 font-weight-light">{{ $t("users.select_user") }} </span>
            </div>
            <div v-else class="text-center w-100 pa-4">
              <v-btn @click="selected = null" x-large icon color="primary" class="d-inline d-md-none">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
              <user-recap
                flat
                :show-student-info="residenceInfo.canViewStudents"
                :user_id="selected.id"
                @saved="(user) => savedUser(user)"
                @deleted="deletedUser"
                @activated="toggledActiveStatus"
                @deactivated="toggledActiveStatus"
              />
            </div>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="createDialog" persistent max-width="800px">
      <user-form
        :baseItem="{ residence, generate_password: true, ...(type ? { type } : {}) }"
        @cancel="createDialog = false"
        @created="createdUser"
      />
    </v-dialog>

    <v-dialog v-model="editDialog" persistent max-width="800px">
      <residence-form :residence_id="residence.id" @cancel="editDialog = false" @updated="editDialog = false" />
    </v-dialog>
  </v-card>
</template>

<script>
import UserRecap from "./UserRecap.vue";
import UserForm from "../forms/UserForm.vue";
import ResidenceForm from "../forms/ResidenceForm.vue";
import gql from "graphql-tag";

export const RESIDENCE_USERS_FRAGMENT = gql`
  fragment residenceUsersFragment on Residence {
    id
    name
    canUpdate
    canManageUsers
    canViewStudents
    area {
      id
      abbreviation
    }
  }
`;

export default {
  components: { UserRecap, UserForm, ResidenceForm },
  name: "ResidenceUsers",
  props: {
    residence: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: undefined,
    },
    excludeTypes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeStatus: true,
      activating: false,
      createDialog: false,
      editDialog: false,
      selected: null,
      filter: "",
      first: 10,
      page: 1,
      typeFilter: this.type,
      showFilters: false,
    };
  },

  apollo: {
    residenceInfo: {
      query: gql`
        query ResidenceInfo($id: ID!) {
          residence(id: $id) {
            id
            name
            canUpdate
            canManageUsers
            canViewStudents
            area {
              id
              abbreviation
            }
          }
        }
      `,
      variables() {
        return {
          id: this.residence.id,
        };
      },
      update: (data) => data.residence,
    },
    users: {
      query: require("../../graphql/queries/UsersFiltered.gql"),
      variables() {
        let variables = {
          residence_id: this.residence.id,
          filter: this.filter,
          activeStatus: this.activeStatus,
          first: this.first,
          page: this.page,
        };
        if (this.typeFilter && this.typeFilter != "all") variables.type = this.typeFilter;

        if (this.excludeTypes.length) variables.excludeTypes = this.excludeTypes;

        return variables;
      },
      update: (data) => data.residence.users,
      fetchPolicy: "network-only",
    },
  },

  watch: {
    showFilters(value) {
      if (!value) this.typeFilter = null;
      this.filter = "";
    },
  },

  computed: {
    loading() {
      return this.$apollo.queries.users.loading;
    },
    error() {
      return this.$apollo.queries.users.error;
    },

    userTypes() {
      return [
        {
          type: "all",
        },
        {
          type: "guest",
          icon: "mdi-account",
        },
        {
          type: "student",
          icon: "mdi-school",
        },
        {
          type: "course_admin",
          icon: "mdi-table-account",
        },
        {
          type: "meals_admin",
          icon: "mdi-silverware-fork-knife",
        },
        {
          type: "receptionist",
          icon: "mdi-card-account-details-outline",
        },
        {
          type: "teacher",
          icon: "mdi-human-male-board",
        },
        {
          type: "residence_admin",
          icon: "mdi-home-account",
        },
        {
          type: "area_admin",
          icon: "mdi-home-city",
        },
        {
          type: "client_admin",
          icon: "mdi-account-cog",
        },
      ];
    },
  },

  methods: {
    toggleActiveStatus() {
      this.activeStatus = !this.activeStatus;
      this.selected = null;
    },

    deletedUser() {
      this.refetch();
      this.selected = null;
    },

    createdUser() {
      this.refetch();
      this.createDialog = false;
    },

    savedUser(user) {
      if (user.residence.id != this.residence.id) {
        this.selected = null;
        this.$emit("userChangedResidence", user.residence);
        this.refetch();
      }

      this.createDialog = false;
    },

    toggledActiveStatus() {
      this.refetch();
      this.selected = null;
    },

    newUser() {
      this.createDialog = false;
      this.refetch();
    },

    refetch() {
      this.$apollo.queries.users.refetch();
    },
  },
};
</script>
