<template>
  <v-row justify="center" no-gutters>
    <v-col v-if="!loading">
      <v-card flat class="w-100">
        <v-card-title
          class="w-100 text-wrap text-uppercase text-h6 text-center text-md-left"
          style="word-break: break-word"
        >
          <span>{{ user.complete_name }}</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-divider></v-divider>

          <v-row no-gutters align="center" justify="center">
            <v-col cols="auto" style="display: inline-flex; min-width: 300px">
              <avatar-picker :user="user" />
            </v-col>

            <v-col cols="auto">
              <v-row class="my-2 text-no-wrap">
                <v-col class="text-sm-right text-center pr-4 pb-0 pb-sm-2" tag="strong" cols="12" sm="5">
                  {{ $t("user.email") }}
                </v-col>
                <v-col class="text-sm-left text-center pt-0 pt-sm-3" cols="12" sm="7"
                  >{{ user.email }}
                  <v-btn v-if="user.email" icon :href="`mailto:${user.email}`" small>
                    <v-icon small> mdi-email </v-icon>
                  </v-btn>
                </v-col>
                <v-col class="text-sm-right text-center pr-4 pb-0 pb-sm-2" tag="strong" cols="12" sm="5">
                  {{ $t("user.phone") }}
                </v-col>
                <v-col class="text-sm-left text-center pt-0 pt-sm-3" cols="12" sm="7"
                  >{{ user.phone }}
                  <v-btn v-if="user.phone" icon :href="`tel:${user.phone}`" small>
                    <v-icon small> mdi-phone </v-icon>
                  </v-btn>
                  <v-btn v-if="user.phone" icon :href="getWhatsappLink(user.phone)" small>
                    <v-icon small> $whatsapp </v-icon>
                  </v-btn>
                </v-col>
                <v-col class="text-sm-right text-center pr-4 pb-0 pb-sm-2" tag="strong" cols="12" sm="5">
                  {{ $t("user.type") }}
                </v-col>
                <v-col class="text-sm-left text-center pt-0 pt-sm-3" cols="12" sm="7">{{
                  $tc(`options.user.type.${user.type}`)
                }}</v-col>
                <v-col
                  v-if="studentIsVisible"
                  class="text-sm-right text-center pr-4 pb-0 pb-sm-2 text-capitalize"
                  tag="strong"
                  cols="12"
                  sm="5"
                >
                  {{ $tc("models.studentYearTag", 1) }}
                </v-col>
                <v-col v-if="studentIsVisible" class="text-sm-left text-center pt-0 pt-sm-3" cols="12" sm="7">{{
                  user.student.latestStudentYear?.studentYearTag?.name ?? ""
                }}</v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions class="mt-3 justify-center">
          <v-tooltip bottom v-if="user.canUpdate">
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="editUser" v-bind="attrs" v-on="on" class="mx-2" icon><v-icon>mdi-pencil</v-icon></v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="user.canToggleActivation">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="togglingActiveStatus"
                @click="toggleActiveStatusDialog = true"
                v-bind="attrs"
                v-on="on"
                color="warning"
                class="mx-2"
                icon
                ><v-icon>{{ user.active ? "mdi-archive" : "mdi-archive-off" }}</v-icon></v-btn
              >
            </template>
            <span>{{ user.active ? $t("users.deactivate") : $t("users.activate") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="user.canDelete">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="deleting"
                @click="deleteDialog = true"
                v-bind="attrs"
                v-on="on"
                color="danger"
                class="mx-2"
                icon
              >
                <v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>{{ $t("delete") }}</span>
          </v-tooltip>
          <v-tooltip v-if="studentIsVisible" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="mx-2"
                :to="{ name: 'UserProfile', params: { id: user.id } }"
                icon
              >
                <v-icon>mdi-school</v-icon>
              </v-btn>
            </template>
            <span class="text-capitalize">{{ $tc("models.student", 1) }}</span>
          </v-tooltip>
          <v-tooltip v-if="candidatureIsVisible" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="mx-2"
                :to="{ name: 'UserProfile', params: { id: user.id } }"
                icon
              >
                <v-icon>mdi-account-box-plus-outline</v-icon>
              </v-btn>
            </template>
            <span class="text-capitalize">{{ $tc("models.candidature", 1) }}</span>
          </v-tooltip>
          <v-menu offset-y v-if="user.isMe || user.canSendLoginInfo || user.canRequestPasswordReset">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="secondary" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-lock</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-if="user.isMe" @click="dialogResetPw = true" dense>
                <v-list-item-title>Reset password</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="user.canSendLoginInfo" @click="sendLoginInfo" dense>
                <v-list-item-title>{{ $t("user.send_login_info") }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="user.canRequestPasswordReset" @click="requestPasswordReset" dense>
                <v-list-item-title>{{ $t("user.request_password_reset") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip bottom v-if="user.canUpdate">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :to="{ name: 'NewChat', params: { user_id: user.id } }"
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="mx-2"
                icon
              >
                <v-icon>mdi-message-text</v-icon>
              </v-btn>
            </template>
            <span>Chat</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>

      <v-dialog v-model="toggleActiveStatusDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>{{
            user.active ? $t("dialog.sure_to_deactivate", user) : $t("dialog.sure_to_activate", user)
          }}</v-card-title>
          <v-card-text>
            {{ user.active ? $t("dialog.deactivate_info") : $t("dialog.activate_info", user) }}
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text color="secondary" @click="toggleActiveStatusDialog = false">{{ $t("dialog.cancel") }}</v-btn>
            <v-btn text color="primary" @click="toggleActiveStatus()">{{ $t("dialog.ok") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-progress-circular color="primary" v-else :size="50" indeterminate />

    <v-dialog v-model="deleteDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>{{ $t("dialog.sure_to_delete") }}</v-card-title>
        <v-card-actions class="justify-end">
          <v-btn text color="secondary" @click="deleteDialog = false">{{ $t("dialog.cancel") }}</v-btn>
          <v-btn text color="primary" @click="deleteUser">{{ $t("dialog.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" persistent max-width="800px">
      <user-form @cancel="editDialog = false" @save="savedUser" :user_id="parseInt(user_id)" />
    </v-dialog>
    <v-dialog v-model="dialogResetPw" persistent max-width="800px">
      <change-password-form @cancel="dialogResetPw = false" @save="dialogResetPw = false" />
    </v-dialog>

    <v-snackbar color="warning" top v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import UserForm from "../forms/UserForm.vue";
import ChangePasswordForm from "../forms/ChangePasswordForm.vue";
import AvatarPicker, { AVATAR_FRAGMENT } from "../profile/AvatarPicker.vue";

const USER_RECAP_FRAGMENT = gql`
  fragment userRecapFragment on User {
    id
    active
    username
    complete_name
    email
    phone
    type
    isMe
    canUpdate
    canDelete
    canSendLoginInfo
    canToggleActivation
    canRequestPasswordReset
    ...avatar
  }
  ${AVATAR_FRAGMENT}
`;

const QUERY_WITH_STUDENT = gql`
  query UserRecapWithStudent($id: ID!) {
    user(id: $id) {
      ...userRecapFragment
      student {
        id
        latestStudentYear {
          id
          studentYearTag {
            id
            name
          }
        }
      }
    }
  }
  ${USER_RECAP_FRAGMENT}
`;

const QUERY_WITHOUT_STUDENT = gql`
  query UserRecap($id: ID!) {
    user(id: $id) {
      ...userRecapFragment
    }
  }
  ${USER_RECAP_FRAGMENT}
`;

export default {
  name: "UserRecap",
  components: { UserForm, ChangePasswordForm, AvatarPicker },
  props: { user_id: { required: true }, showStudentInfo: { type: Boolean, default: false } },
  data() {
    return {
      editDialog: false,
      editedUser: null,
      toggleActiveStatusDialog: false,
      deleteDialog: false,
      togglingActiveStatus: false,
      deleting: false,
      dialogResetPw: false,
      snackbar: false,
      snackbarText: null,
      publicPath: process.env.BASE_URL,
    };
  },

  computed: {
    loading() {
      return this.$apollo.queries.user.loading;
    },

    studentIsVisible() {
      return this.showStudentInfo && this.user?.student && this.user?.type === "student";
    },

    candidatureIsVisible() {
      return this.user?.type === "candidate";
    },
  },

  apollo: {
    user: {
      query() {
        return this.showStudentInfo ? QUERY_WITH_STUDENT : QUERY_WITHOUT_STUDENT;
      },
      variables() {
        return { id: this.user_id };
      },
    },
  },
  methods: {
    savedUser(user) {
      this.editDialog = false;
      this.$emit("saved", user);
    },

    editUser() {
      this.editDialog = true;
    },

    saveUser() {},

    toggleActiveStatus() {
      this.toggleActiveStatusDialog = false;
      this.togglingActiveStatus = true;

      let mutation;
      if (this.user.active) mutation = "deactivateUser";
      else mutation = "activateUser";

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ToggleActiveStatus($id: ID!) {
              ${mutation}(id: $id) {
                id
                active
              }
            }
          `,
          variables: {
            id: this.user_id,
          },
        })
        .then(({ data }) => {
          this.togglingActiveStatus = false;
          this.$emit(data[mutation].active ? "activated" : "deactivated", data[mutation].id);
        })
        .catch((error) => {
          this.togglingActiveStatus = false;
          console.error(error);
        });
    },

    deleteUser() {
      this.deleteDialog = false;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteUser($id: ID!) {
              deleteUser(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: this.user_id,
          },
        })
        .then(({ data }) => {
          this.deleting = false;
          this.$emit("deleted", data.deleteUser.id);
        })
        .catch((error) => {
          this.deleting = false;
          console.error(error);
        });
    },

    sendLoginInfo() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation SendLoginInfo($id: ID!) {
              sendLoginInfo(id: $id) {
                id
                canSendLoginInfo
              }
            }
          `,
          variables: {
            id: this.user_id,
          },
        })
        .then(() => {
          this.snackbarText = this.$t("user.login_info_sent");
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    requestPasswordReset() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation RequestPasswordReset($id: ID!) {
              requestPasswordReset(id: $id)
            }
          `,
          variables: {
            id: this.user_id,
          },
        })
        .then(({ data }) => {
          this.snackbarText = this.$t(data.requestPasswordReset);
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getWhatsappLink(phone) {
      return `https://wa.me/${phone.replace(/^0+/, "")}`;
    },
  },
};
</script>
