<template>
  <base-form
    model="user"
    @save="changePassword"
    :fields="fields"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    :graphQLErrors="graphQLErrors"
    @cancel="$emit('cancel')"
    ref="form">
    <template #title>Reset password</template>
  </base-form>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";

import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";

export default {
  components: { BaseForm },
  name: "UserForm",

  props: {
    user_id: Number,
    duplicating: Boolean,
  },

  data() {
    return {
      newValue: {},
      saving: false,
      graphQLErrors: [],
    };
  },

  watch: {
    newValue() {
      this.graphQLErrors = [];
    },
  },

  computed: {
    fields() {
      return [
        {
          name: "new_password",
          validation: {
            minLength: minLength(8),
            ...(this.user_id ? {} : { required }),
          },
          bind: {
            type: "password",
          },
          autocomplete: "current-password",
        },
        {
          name: "password_confirm",
          validation: {
            sameAsPassword: sameAs("new_password"),
            required,
          },
          bind: {
            type: "password",
          },
        },
        {
          name: "old_password",
          bind: {
            type: "password",
          },
          validation: {
            required,
          },
        },
      ];
    },
  },

  methods: {
    changePassword() {
      this.saving = true;

      let input = Object.assign({}, this.newValue);
      delete input.password_confirm;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation changePassword($input: ChangePasswordInput!) {
              changePassword(input: $input) {
                id
              }
            }
          `,
          variables: { input },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.$emit("save");
          this.saving = false;
        })
        .catch(({ graphQLErrors }) => {
          this.graphQLErrors = graphQLErrors;
          this.saving = false;
        });
    },
  },
};
</script>
