<template>
  <v-container>
    <v-card class="mb-4" rounded="lg">
      <v-card-text>
        <ManagedResidencesSelect auto-select-first v-model="selectedResidence"></ManagedResidencesSelect>
      </v-card-text>
    </v-card>
    <v-card rounded="lg" class="overflow-hidden">
      <residence-users v-if="selectedResidence" :residence="selectedResidence" type="candidate" />
    </v-card>
  </v-container>
</template>

<script>
import ManagedResidencesSelect from "../components/inputs/ManagedResidencesSelect.vue";
import ResidenceUsers from "../components/users/ResidenceUsers.vue";

export default {
  components: { ManagedResidencesSelect, ResidenceUsers },
  name: "Candidatures",

  data() {
    return {
      selectedResidence: null,
      residence: null,
    };
  },

  methods: {},
};
</script>
